import React, { memo } from 'react'
import Head from 'next/head'

const SocialMediaSeoTags = ({
  ogTitle,
  ogSiteName = 'ClikAuto',
  ogUrl = 'https://clikauto.com',
  ogDescription,
  ogType = 'website',
  ogImage,
  twitterTitle,
  twitterDescription,
  twitterCard = 'summary',
  twitterSite = '@clikauto_',
  twitterImage,
}) => {
  return (
    <Head>
      <meta
        property="og:title"
        content={ogTitle}
      />
      <meta
        property="og:site_name"
        content={ogSiteName}
      />
      <meta
        property="og:url"
        content={ogUrl}
      />
      <meta
        property="og:description"
        content={ogDescription}
      />
      <meta
        property="og:type"
        content={ogType}
      />
      <meta
        property="og:image"
        content={ogImage}
      />
      <meta
        name="twitter:card"
        content={twitterCard}
      />
      <meta
        name="twitter:site"
        content={twitterSite}
      />
      <meta
        name="twitter:title"
        content={twitterTitle}
      />
      <meta
        name="twitter:description"
        content={twitterDescription}
      />
      <meta
        name="twitter:image"
        content={twitterImage}
      />
    </Head>
  )
}

export default memo(SocialMediaSeoTags)
